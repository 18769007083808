module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"강남 퍼펙트","short_name":"강남 퍼펙트","description":"강남 퍼펙트의 공식 사이트입니다. 언제나 최고의 서비스를 제공해 드리겠습니다. 방문 전 꼭 전화 부탁드립니다. 주대20만 티씨12만 룸티5만 입니다.","start_url":"/","display":"standalone","theme_color":"#000000","background_color":"#000000","lang":"ko","orientation":"portrait-primary","icon":"./src/static/images/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dfa456ac1ecd9a15bd8b5609d7661649"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
